import React, { useState, useMemo, useEffect } from "react";
import { FaArrowDown, FaEye } from "react-icons/fa";
import Modal from "../../../components/modal";
import Layout from "../../../components/layout";
import CircleLoading from "../../../components/circle-loading";
import queryMenu from "../../../graphql/menu";
import { formatGQLQuery } from "../../../utils";
import {
    popularArticles as popularArticlesQuery,
} from "../../../graphql/article";
import Button from "../../../components/common/button";
import contactAndAddress from "../../../graphql/footer";

export default function Ppiu(props) {
    const {
        menu,
        query: queryDefault,
        contact = {},
        address = {},
        additionalSearchData = null
    } = props.serverData;
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(0);
    const [lengthData, setLengthData] = useState(0);
    const [showLoadMore, setShowLoadMore] = useState(true);
    const [dataDetail, setDataDetail] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const query = useMemo(() => {
        if (queryDefault || queryDefault.q) {
            return queryDefault.q;
        }
        return null;
    }, [queryDefault]);


    const onLoadMore = () => {
        setPage(page + 1);
        const sliceData = filteredData.splice(page, page + 10);
        setResults((prev) => {
            return [...prev, ...sliceData];
        });
    };

    const filteredData = useMemo(() => {
        if (additionalSearchData && Array.isArray(additionalSearchData.data)) {
            if (query) {
                return additionalSearchData.data.filter(item => String(item.nama_ppiu).indexOf(query) > -1)
            }
            return additionalSearchData?.data
        }
        return []
    }, [additionalSearchData]);

    useEffect(() => {
        if (filteredData && Array.isArray(filteredData)) {
            if (filteredData.length === 0) {
                setShowLoadMore(false);
            } else {
                const sliceData = filteredData.splice(page, page + 10);
                setLengthData(filteredData.length);
                setResults((prev) => {
                    return [...prev, ...sliceData];
                });
            }
        }
    }, [filteredData]);

    const onCloseModal = () => {
        setIsVisible(false);
        setDataDetail({});
    };
    const showDetail = (data) => {
        setDataDetail(data);
        setIsVisible(true);
    };

    return (
        <Layout menu={menu} contact={contact} address={address} {...props}>
            <main className="flex-1">
                <div className="container my-10">
                    <div className="flex flex-col items-start gap-10 md:flex-row">
                        <div className="w-full md:w-full">
                            <div className="text-3xl font-semibold text-gray-400">
                                Hasil untuk <span className="text-fontPrimary">{query}</span>
                            </div>
                            <Content
                                query={query}
                                isShowMore={showLoadMore}
                                results={results}
                                // error={error}
                                onLoadMore={onLoadMore}
                                showDetail={showDetail}
                            />
                            <ModalDetail
                                isVisible={isVisible}
                                onClose={onCloseModal}
                                data={dataDetail}
                            />
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}
const ModalDetail = ({ isVisible, onClose, data }) => {
    return (
        <Modal
            visible={isVisible}
            onClose={onClose}
            title={data.ppiu_id + " - " + data.nama_ppiu}
        >
            <div>
                <table>
                    <tr>
                        <td className="m-w-100">Tanggal SK</td>
                        <td>:</td>
                        <td>{data.tanggal_sk}</td>
                    </tr>
                    <tr>
                        <td>Nomor SK</td>
                        <td>:</td>
                        <td>{data.nomor_sk}</td>
                    </tr>
                    <tr>
                        <td>Status PIHK</td>
                        <td>:</td>
                        <td>{data.status_ppiu}</td>
                    </tr>
                    <tr>
                        <td>Akreditasi</td>
                        <td>:</td>
                        <td>{data.nilai_akreditasi}</td>
                    </tr>
                    <tr>
                        <td>No Telepon</td>
                        <td>:</td>
                        <td>{data.no_telp}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>:</td>
                        <td>{data.email}</td>
                    </tr>
                    <tr>
                        <td>Alamat</td>
                        <td>:</td>
                        <td>{data.alamat}</td>
                    </tr>
                </table>
            </div>
        </Modal>
    );
};
const Content = ({
    query,
    loading,
    results,
    error,
    isShowMore,
    onLoadMore,
    showDetail,
}) => {
    if (!query) {
        return null;
    }
    if (loading && results.length === 0) return <CircleLoading />;

    if (!results || results.length === 0 || error) {
        return (
            <div className="text-center">
                <p className="mt-4 text-gray-400">
                    {error
                        ? "Maaf, telah terjadi kesalahan internal"
                        : "Kami tidak menemukan hasil. Mohon coba kata kunci yang berbeda."}
                </p>
            </div>
        );
    }
    return (
        <div className="my-4 w-full relative overflow-x-auto">
            <div className="mt-3">
                <table className="w-full table-auto text-center">
                    <thead className="border-b bg-neutral-800 font-medium text-white dark:border-neutral-500 dark:bg-neutral-900">
                        <tr>
                            <th className=" px-6 py-4">No</th>
                            <th className=" px-6 py-4">Nama</th>
                            <th className=" px-6 py-4">Nomor SK</th>
                            <th className=" px-6 py-4">Kontak Alamat</th>
                            <th className=" px-6 py-4">Cabang</th>
                            <th className=" px-6 py-4">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((data, index) => {
                            return (
                                <tr
                                    className="border-b dark:border-neutral-500"
                                    key={"table-ppiu-" + index}
                                >
                                    <td className=" px-6 py-4">{index + 1}</td>
                                    <td className=" px-6 py-4">{data.nama_ppiu}</td>
                                    <td className=" px-6 py-4">{data.nomor_sk}</td>
                                    <td className=" px-6 py-4">{data.alamat}</td>
                                    <td className="min-w-100">{data.cabang}</td>
                                    <td className="min-w-350 py-4">
                                        <Button
                                            className="inline-flex min-w-full items-center"
                                            onClick={() => showDetail(data)}
                                        >
                                            <FaEye className="mr-2" /> Detail
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {isShowMore && (
                <Button
                    onClick={onLoadMore}
                    className={`mx-auto mt-5 flex flex-row items-center`}
                >
                    Muat lebih banyak <FaArrowDown className="ml-2" />
                </Button>
            )}
        </div>
    );
};

export async function getServerData({ query, params }) {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    const isoThirtyDaysAgo = thirtyDaysAgo.toISOString().toString();

    try {
        const response = await fetch(process.env.GRAPHQL_URL_SSR, {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: formatGQLQuery(
                    [queryMenu, popularArticlesQuery, contactAndAddress],
                    [
                        { key: "$popularArticlesLimit", type: "Int!" },
                        { key: "$popularArticlesMaxDate", type: "DateTime!" },
                    ]
                ),
                variables: {
                    popularArticlesLimit: 5,
                    popularArticlesMaxDate: isoThirtyDaysAgo,
                },
            }),
        });
        let additionalSearchData;
        if (query && query?.province) {
            const provinceId = query?.province;
            const url = process.env.SISKPATUH_API_URL + `/ppiu/${provinceId}`;
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-key": process.env.XKEY_SISKOPATUH,
                },
                agent: new (require('https').Agent)({ rejectUnauthorized: false }) // Bypass SSL verification
            }
            additionalSearchData = await fetch(url, requestOptions).then(res => {
                if (res.ok) return res.json();
                return null;
            })
        }

        const { data } = await response.json();
        return {
            props: {
                menu: data?.menu?.data,
                popularArticles: data?.popularArticles?.data,
                query,
                contact: data?.contact?.data,
                address: data?.address?.data,
                additionalSearchData
            },
            status: 200,
        };
    } catch (error) {
        return {
            status: 500,
        };
    }
}
